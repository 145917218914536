
import CommonUserChoiceModal from "../../../common-components/CommonUserChoiceModal.vue";
import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from '../../../config';
import Axios from 'axios';
import { authHeader } from '../../../services/auth'
import component from "*.vue";

@Component({ components: {CommonUserChoiceModal } })
export default class Settings extends Vue{
    public workFlowList: any = [];
    public days = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20];
    // public datesOfTasks:any 

    public async getWorkFlowList() {
        this.$store.state.tmpcoLoader = true;
        try {
            const response = await Axios.get(`${BASE_API_URL}super-admin/settings/getWorkFlowList`,
            { headers: authHeader() },
            );
            if (response.status === 201) {
                this.workFlowList = response.data;
            }
        } catch (error) {
        console.log(error);
        }
        this.$store.state.tmpcoLoader = false;
    }

    public async saveDueDatesOfWorkFlowTasks() {
        this.$store.state.tmpcoLoader = true;
        try {
            const response = await Axios.post(`${BASE_API_URL}super-admin/settings/saveDueDatesOfWorkFlowTasks`,
            this.workFlowList,
            { headers: authHeader() },
            );
            if (response.status === 201) {
                this.$snotify.success(response.data.message);
            }
            else{
                this.$snotify.error(response.data.message);
            }
        } catch (error) {
        console.log(error);
        }
        this.$store.state.tmpcoLoader = false;
    }


    public async saveWorkFlowData(data){
        this.$store.state.tmpcoLoader = true;
        try {
        await this.$refs.refrence["confirmationBox"]({
        msg: `Are you sure want to update the ${data.name} ?`,
        data: data,
        apiPath: "super-admin/settings/updateWorkFlowData",
      });
      }catch (error) {
            console.log(error);
        }
        this.$store.state.tmpcoLoader = false;
    }

    mounted() {
        this.getWorkFlowList();
    }

}
